//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: "SectionSocialImpact",
    props: {
    },
};
